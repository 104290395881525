import Vue from "vue";
import Vuex from "vuex";

import {
	productsList,
	product,
	getPurchaseList,
	user,
	sendOrderPix,
	zipCode,
	generateToken,
	sendOrder,
	freight,
	cupom,
	banner,
	// searchMooveApi,
	sendSac,
	pickoff,
	blacklist,
	jadlogFrete,
	// getSac,
	getSacItem,
	getCoupons,

	deliveryPersonLogin,

	restaurantValidateCNPJ,
	restaurantLogin,
	restaurantRegister,

	getCustomer,
	getCustomerAddress,
	editCustomerAddress,
	newCustomerAddress,
	deleteCustomerAddress,
} from "@/services";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		product: {},
		productVariant: {},
		productsLoading: false,
		productsList: [],
		productsListLoading: false,
		myPurchaseListLoading: false,
		myPurchaseList: [],
		cart: [],
		total: 0,
		user: {},
		orderNumber: "",
		zip: {},
		order: {},
		weight: 0,
		cupom: null,
	},
	getters: {
		productsList: (state) => state.productsList,
		productsListTotal: (state) => state.productsList.total,
		productsListLoading: (state) => state.productsListLoading,
		myPurchaseList: (state) => state.myPurchaseList,
		myPurchaseListLoading: (state) => state.myPurchaseListLoading,
		getProduct: (state) => state.product,
		getProductVariant: (state) => state.productVariant,
		productsLoading: (state) => state.productsLoading,
		getCart: (state) => state.cart, //JSON.parse(window.sessionStorage.getItem("cart")),
		getTotal: (state) => state.total,
		getWeight: (state) => state.weight,
		getUser: (state) => state.user,
		getZip: (state) => state.zip,
		getOrderNumber: (state) => state.orderNumber,
	},
	mutations: {
		["SET_PRODUCT"](state, data) {
			state.product = data;
		},
		["SET_PRODUCT_VARIANT"](state, data) {
			state.productVariant = data;
		},
		["SET_USER"](state, data) {
			state.user = data ;
		},
		["SET_ZIP"](state, data) {
			state.zip = data;
		},
		// ["SET_ZIP_MOOVE"](state, data) {
		// 	state.zip = data;
		// },
		["SET_CUPOM"](state, data) {
			state.cupom = data;
		},
		["SET_ORDER"](state, data) {
			state.order = data;
		},
		["SET_PRODUCTS_LOADING"](state, data) {
			state.productsLoading = data;
		},
		["SET_PRODUCTS_LIST"](state, data) {
			state.productsList = data;
		},
		["SET_PRODUCTS_LIST_TOTAL"](state, data) {
			state.productsList.total = data;
		},
		["SET_PRODUCTS_LIST_LOADING"](state, data) {
			state.productsListLoading = data;
		},
		["SET_MY_PURCHASE_LIST"](state, data) {
			state.myPurchaseList = data;
		},
		["SET_MY_PURCHASE_LIST_LOADING"](state, data) {
			state.myPurchaseListLoading = data;
		},
		["SET_TOKEN"](state, data) {
			console.log(data);
		},
		["GET_ORDER_NUMBER"](state, data) {
			console.log(state, data);
		},
		["SAVE_SAC"](state, data) {
			state.order = data;
		},
		["ADD_PRODUCT_CART"](state, data) {
			const price = data.precoPromocional ? parseFloat(data.precoPromocional) : parseFloat(data.preco);
			const duplicated = state.cart.findIndex(item => item.id === data.id);

			if (duplicated !== -1) {
				const totalQuantity = state.cart[duplicated].quantity + data.quantity;
				state.total -= parseFloat((state.cart[duplicated].precoPromocional ? state.cart[duplicated].precoPromocional : state.cart[duplicated].preco) * state.cart[duplicated].quantity);
				state.weight -= parseFloat(state.cart[duplicated].pesoBruto * state.cart[duplicated].quantity);

				state.cart[duplicated].quantity = totalQuantity;

				state.total += price * totalQuantity;
				state.weight += parseFloat(data.pesoBruto) * totalQuantity;
			} else {
				if (data.categoria.descricao.toLowerCase() === 'pneus') {
					const totalPneus = state.cart.reduce((acc, item) => {
						if (item.categoria.descricao.toLowerCase() === 'pneus') {
							acc += item.quantity;
						}
						return acc;
					}, 0);
					if (totalPneus + data.quantity > 4) {
						return;
					}
				}
				state.cart.push(data);
				state.total += price * data.quantity;
				state.weight += parseFloat(data.pesoBruto) * data.quantity;
			}

			window.sessionStorage.setItem("cart", JSON.stringify(state.cart));
		},
		["CHANGE_QUANTITY_CART"](state, data) {
			const index = data.index;
			let novo = JSON.parse(JSON.stringify(data.item));

			const oldItem = state.cart[index];
			const oldPrice = oldItem.precoPromocional
				? parseFloat(oldItem.precoPromocional)
				: parseFloat(oldItem.preco);

			state.total -= oldPrice * oldItem.quantity;
			state.weight -= parseFloat(oldItem.pesoBruto) * oldItem.quantity;

			state.cart.splice(index, 1, novo);

			const newPrice = novo.precoPromocional
				? parseFloat(novo.precoPromocional)
				: parseFloat(novo.preco);

			state.total += newPrice * novo.quantity;
			state.weight += parseFloat(novo.pesoBruto) * novo.quantity;

			window.sessionStorage.setItem("cart", JSON.stringify(state.cart));
		},
		["REMOVE_FROM_CART"](state, data) {
			const index = data.index;
			const item = state.cart[index];

			const itemPrice = item.precoPromocional
				? parseFloat(item.precoPromocional)
				: parseFloat(item.preco);

			state.total -= itemPrice * item.quantity;
			state.weight -= parseFloat(item.pesoBruto) * item.quantity;

			state.cart.splice(index, 1);

			window.sessionStorage.setItem("cart", JSON.stringify(state.cart));
		},
		["CLEAR_CART"](state) {
			state.cart = [];
			state.total = 0;
			state.weight = 0;
		},
	},
	actions: {
		restoreUser({ commit }) {
			const userData = localStorage.getItem("userData");
			const user = userData ? JSON.parse(userData) : null;
			user ? commit('SET_USER', user.user) : null;
		},
		async fetchProductsList({ commit }, params) {
			commit("SET_PRODUCTS_LIST_LOADING", true);
			try {
				const { data } = await productsList(params);
				commit("SET_PRODUCTS_LIST", data.items);
				commit("SET_PRODUCTS_LIST_LOADING", false);
				commit("SET_PRODUCTS_LIST_TOTAL", data.total);
			} catch (error) {
				commit("SET_PRODUCTS_LIST_LOADING", false);
				return Promise.reject(error);
			}
		},
		async getUserFromIFood({ commit }, params) {
			try {
			  const { data } = await user(params);
			  commit("SET_USER", Object.assign({}, data));
			} catch (error) {
			  return Promise.reject(error);
			}
		},
		async fetchMyPurchaseList({ commit }, params) {
			try {
				const response = await getPurchaseList(params);
				if (response && response.data) {
					const { data } = response;
					commit("SET_MY_PURCHASE_LIST", data.items);
					commit("SET_MY_PURCHASE_LIST_LOADING", false);
				} else {
					commit("SET_MY_PURCHASE_LIST_LOADING", false);
				}
			} catch (error) {
				commit("SET_MY_PURCHASE_LIST_LOADING", false);
				return Promise.reject(error);
			}
		},
		async fetchProduct({ commit }, params) {
			try {
				const { data } = await product(params);
				commit("SET_PRODUCT", Object.assign({}, data.items[0]));
				commit("SET_PRODUCTS_LOADING", true);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchProductVariant({ commit }, params) {
			try {
				const { data } = await product(params);
				commit("SET_PRODUCT_VARIANT", Object.assign({}, data.items[0]));
				commit("SET_PRODUCTS_LOADING", true);
				return data.items[0];
			} catch (error) {
				return Promise.reject(error);
			}
		},
		addToCart({ commit }, params) {
			try {
				commit("ADD_PRODUCT_CART", params);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		changeQuantity({ commit }, params) {
			try {
				commit("CHANGE_QUANTITY_CART", params);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async removeProduct({ commit }, params) {
			try {
				commit("REMOVE_FROM_CART", params);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async clearCart({ commit }, params) {
			try {
				commit("CLEAR_CART", params);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async searchZipCode({ commit }, params) {
			try {
				const { data } = await zipCode(params);
				commit("SET_ZIP", data);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async calculateFreight({ commit }, params) {
			if (params.frete[0].peso < 1) params.frete[0].peso = Math.ceil(params.frete[0].peso * 4) / 4;

			try {
				const { data } = await freight(params);
				commit("SET_ZIP", data);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async checkCupom({ commit }, params) {
			try {
				const { data } = await cupom(params);
				commit("SET_CUPOM", data);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async tokenGenerator({ commit }, params) {
			try {
				const { data } = await generateToken(params);
				commit("SET_TOKEN", data);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async makeOrder({ commit }, params) {
			try {
				const { data } = await sendOrder(params);
				commit("SET_ORDER", data);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async makeOrderPix({ commit }, params) {
			try {
				const { data } = await sendOrderPix(params);
				commit("SET_ORDER", data);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async getOrderNumber({ commit }, params) {
			console.log(params);
			commit("");
			// try {
			// 	const { data } = await getOrderNumber(params);
			// 	commit("GET_ORDER_NUMBER", Object.assign({}, data))
			// } catch (error) {
			// 	return Promise.reject(error);
			// }
		},
		async fetchBanner({ commit }, params) {
			try {
				console.log("commit: ", commit);
				const { data } = await banner(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async saveSacMessage({ commit }, params) {
			try {
				const { data } = await sendSac(params);
				commit("SAVE_SAC", data);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchPickoff({ commit }, params) {
			try {
				console.log("commit: ", commit);
				const { data } = await pickoff(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchBlacklist({ commit }, params) {
			try {
				console.log("commit: ", commit);
				const { data } = await blacklist(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchJadlogFrete({ commit }, params) {
			try {
				console.log("commit: ", commit);
				const { data } = await jadlogFrete(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchSacItem({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await getSacItem(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchCoupons({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await getCoupons(params);

				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchDeliveryPersonLogin({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await deliveryPersonLogin(params);
				localStorage.setItem("userData", JSON.stringify(data));
				commit("SET_USER", data.user);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchRestaurantValidateCNPJ({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await restaurantValidateCNPJ(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchRestaurantLogin({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await restaurantLogin(params);
				localStorage.setItem("userData", JSON.stringify(data));
				commit("SET_USER", data.user);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchRestaurantRegister({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await restaurantRegister(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},




		async fetchCustomer({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await getCustomer(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchCustomerAddress({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await getCustomerAddress(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchEditCustomerAddress({ commit }, params) {
			try {
				console.log(commit)
				const { data } = await editCustomerAddress(params.id, params.body);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchNewCustomerAddress({ commit }, params) {
			try {
				console.log(commit)
				const { data } = await newCustomerAddress(params.body);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async fetchDeleteCustomerAddress({ commit }, params) {
			try {
				console.log('commit: ', commit)
				const { data } = await deleteCustomerAddress(params);
				return data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
	},
	modules: {},
});