import axios from "axios";
let baseURL;

console.log(location.hostname)

switch (location.hostname) {
  case "localhost":
    baseURL = "https://api-dev.lojadoentregadorifood.com.br/";
    break;
  case "lojadoentregadorifood.com.br":
    baseURL = "https://api.lojadoentregadorifood.com.br/";
    break;
  default:
    baseURL = "https://api-dev.lojadoentregadorifood.com.br/";
    break;
}

const external = axios.create({
  baseURL: baseURL
});


export {
  external
};