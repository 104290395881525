import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue' 
import HomeView from '../views/HomeView_New.vue'
import RoupasEAcessoriosView from '../views/HomeView.vue'
import MotoPecasView from '../views/MotoPecasView.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: HomeView
},
{
	path: '/app',
	name: 'homeVw',
	component: HomeView
},
{
	path: '/roupas-e-acessorios',
	name: 'RoupasEAcessoriosView',
	component: RoupasEAcessoriosView
},
{
	path: '/motopecas',
	name: 'MotoPecasView',
	component: MotoPecasView
},
{
	// path: '/product/:id',
	path: '/:category/:name/:id',
	name: 'product',
	component: () => import( /* webpackChunkName: "ProductView" */ '../views/ProductView.vue')
},
{
	path: '/product-list/:categoria/:search',
	name: 'product-list',
	component: () => import( /* webpackChunkName: "ProductsListView" */ '../views/ProductsListView.vue')
},
{
	path: '/my-purchases',
	name: 'my-purchases',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/PurchaseView.vue')
},
{
	path: '/my-coupons',
	name: 'my-coupons',
	component: () => import('../views/CouponsView.vue')
},
{
	// path: '/coupon-product-list/:categoria?/:search?',
	path: '/coupon-product-list',
	name: 'coupon-product-list',
	component: () => import('../views/CouponProductsListView.vue')
},
{
	path: '/purchase',
	name: 'purchase',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/Purchase.vue')
},
{
	path: '/checkout',
	name: 'checkout',
	component: () => import( /* webpackChunkName: "CheckoutView" */ '../views/CheckoutView.vue')
},
{
	path: '/my-info',
	name: 'my-info',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/PersonalInfo.vue')
},
{
	path: '/exchange-return',
	name: 'exchange-return',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/ExchangeReturn.vue')
},
{
	path: '/ops',
	name: 'ops',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/Ops.vue')
},
{
	path: '/para-entregadores',
	name: 'ParaEntregadores',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/ParaEntregadores.vue')
},
{
	path: '/para-restaurantes',
	name: 'ParaRestaurantes',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/ParaRestaurantes.vue')
},
{
	path: '/my-data',
	name: 'my-data',
	component: () => import( /* webpackChunkName: "PurchaseView" */ '../views/MyDataView.vue')
},
]

const router = new VueRouter({
	// mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	if (to.name === 'checkout') {
		router.app.$emit('showCartIcon', true)
	} else {
		router.app.$emit('showCartIcon', false)
	}
	next()
})

export default router