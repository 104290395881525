import axios from "axios";
// import store from "../store/index";
let baseURL;

console.log(location.hostname)

switch (location.hostname) {
  case "localhost":
    // baseURL = "https://api.lojadoentregadorifood.com.br/";
    baseURL = "https://api-dev.lojadoentregadorifood.com.br/";
    break;
  case "lojadoentregadorifood.com.br":
    baseURL = "https://api.lojadoentregadorifood.com.br/";
    break;
  default:
    baseURL = "https://api-dev.lojadoentregadorifood.com.br/";
    break;
}

const http = axios.create({
  baseURL: baseURL//process.env.VUE_APP_API,
});

http.interceptors.request.use(
  async (config) => {

    const apiKey = localStorage.getItem('api-key');
    const urlParams = new URLSearchParams(window.location.search);
    try{
      if (config.url.includes("login")) {
        return config;
      } else {
        if(localStorage.getItem('worker_id')){
          if (urlParams.has("public_id")) {
            config.headers["api-id"] = localStorage.getItem("api-id");
          } else if (localStorage.getItem('worker_id')) {
            config.headers["api-id"] = localStorage.getItem("api-id");
          }
      }

        config.headers["api-key"] = apiKey;

        return config;
      }
    }
    catch(e){
      console.log(e)
    } 
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const loginResponse = await doLogin();

        if (loginResponse.isLoggedIn || loginResponse.status === 200) {
          const newApiKey = localStorage.getItem('api-key');
          originalRequest.headers['api-key'] = newApiKey;
          return http(originalRequest);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

async function doLogin() {
  const queryString = decodeURI(window.location.search);
  const urlParams = new URLSearchParams(queryString);
  const publicIdFromUrl = urlParams.get("public_id");

  const apiKey = localStorage.getItem("api-key");
  const apiId = localStorage.getItem("api-id");
  const publicIdFromLS = localStorage.getItem("worker_id");

  if (publicIdFromUrl) {
    try {
      const body = {
        identification: publicIdFromUrl,
      };

      const loginStatus = await http.post('/login', body);

      localStorage.setItem("api-key", loginStatus.data["api-key"]);
      localStorage.setItem("api-id", loginStatus.data["api-id"]);
      localStorage.setItem("worker_id", loginStatus.data.user.id);

      return loginStatus;
    } catch (error) {
      console.error("Erro ao efetuar login:", error);
      throw error;
    }
  } else if (apiKey && apiId && publicIdFromLS) {
    return { isLoggedIn: true };
  } else {
    return { isLoggedIn: false };
  }
}

function iniciarObservadorDeToken() {
  setInterval(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const decoded = decodeJWT(user['api-key']);
    if (decoded && decoded.exp) {
      const nowTime = Math.floor(Date.now() / 1000);
      const timeToExpire = decoded.exp - nowTime;

      if (timeToExpire < 300) {
        renovarToken();
      }
    }
  }, 120000);
}

async function renovarToken() {
  try {
    var user = JSON.parse(localStorage.getItem("userData"));
    const { data } = await axios.post(`${baseURL}auth/${user['api-id']}/refresh-token`, {
      refreshToken: user.refreshToken
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    localStorage.setItem("api-key", data["api-key"]);
    localStorage.setItem("userData", JSON.stringify(data));
  } catch (error) {
    window.location.href = "/";
  }
}

function decodeJWT(token) {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Token JWT inválido');
  }

  const payload = parts[1];
  const decodedPayload = JSON.parse(atob(payload)); 

  return decodedPayload;
}

if (localStorage.getItem("userData")) {
  iniciarObservadorDeToken();
}

export {
  http
};