var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"mb-4"},[_c('div',{staticClass:"banner",class:_vm.isDesk ? 'container' : ''},[_c('carousel',{attrs:{"per-page":1,"mouse-drag":false,"autoplay":true,"loop":true,"navigationEnabled":false,"paginationEnabled":false,"autoplayTimeout":5000}},_vm._l((_vm.banner.items),function(item,index){return _c('slide',{key:index,attrs:{"data-index":item.index,"data-name":item.name}},[_c('img',{staticClass:"carouselImage",attrs:{"data-test":`carousel-${_vm.formatName(item.name)}`,"src":item.url,"alt":item.name},on:{"click":function($event){return _vm.goToRoute(item)}}})])}),1)],1)]),_c('section',{staticClass:"cat"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('img',{attrs:{"data-test":"category-roupas-e-acessorios","src":require("@/assets/img/png/roupas-e-acessorios.png")},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                path: 'roupas-e-acessorios',
              })}}})]),_c('div',{staticClass:"col"},[_c('img',{attrs:{"data-test":"category-motopecas","src":require("@/assets/img/png/motopecas.png")},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                path: 'motopecas',
              })}}})])])])]),_c('section',{staticClass:"container p-0 cpc"},[_c('h1',{staticClass:"h4 pl-3 mb-4"},[_vm._v("Compre por Categoria:")]),_c('div',{staticClass:"container"},[_c('carousel',{attrs:{"perPageCustom":[
          [320, 3],
          [375, 4],
          [768, 8],
          [1024, 12],
        ],"navigationEnabled":true,"paginationEnabled":false}},[_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-acessorios"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'ACESSÓRIOS' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-acessorios.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Acessórios")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-bag"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'BAG' }, //BAG
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-bags.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Bag")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-eletrica"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'ELÉTRICA' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-eletrica.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Elétrica")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-iluminacao"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'ILUMINAÇÃO' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-iluminacao.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Iluminação")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-kits"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'KITS' }, //KITS
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-kits.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Kit")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-motor"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'MOTOR' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-motores.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Motor")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-pneus"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'PNEUS' }, //PNEUS
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-pneus-e-camaras.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Pneus e Câmaras")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-retrovisores"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'RETROVISORES' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-retrovisores.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Retrovisor")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-suspensao"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'SUSPENSÃO' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-suspensao.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Suspensão")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-transmissao"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'TRANSMISSÃO' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-transmissao.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Transmissão")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-vestuario"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'VESTUÁRIO' }, //VESTUÁRIO
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-vestuario.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Vestuário")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",attrs:{"data-test":"category-calcados"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'CALÇADOS' }, //CALÇADOS
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/png/cat-calcados.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Calçados")])])],1)],1)]),(
      _vm.productsList.some(
        (item) =>
          item.product &&
          item.product.categoria &&
          item.product.categoria?.descricao &&
          (item.product.categoria?.descricao?.includes('ÓLEO') ||
            item.product.categoria?.descricao?.includes('PNEUS'))
      )
    )?_c('section',{staticClass:"container p-0 mb-4"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Destaques em Motopeças:")]),_c('div',{staticClass:"container horizontal-scrollable"},[_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.productsList),function(product,index){return _c('div',{key:index},[_c('div',{staticClass:"card",style:(product.categoria.descricao == 'PNEUS'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'PNEU'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'OLEO'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'OLEOS'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'ÓLEOS'
                ? ''
                : 'display: none;'),attrs:{"data-test":`product-${_vm.formatName(product.descricao)}`},on:{"click":function($event){$event.preventDefault();return _vm.goToProduct(
                product.categoria.descricao,
                product.descricao,
                product.id
              )}}},[(product.imagem[0] && product.imagem[0].link)?_c('div',{staticClass:"card-img",style:(`background-image: url(${_vm.ajustarImg(
                product.imagem[0].link
              )})`)}):_c('div',{staticClass:"card-img",style:(`background-image: url(${product.imageThumbnail})`)}),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(product.descricao))]),_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" "),_c('span',{staticStyle:{"font-size":"8px"}},[_vm._v(" no Pix ")])]),_c('small',[_vm._v("ou 3 x de "+_vm._s(_vm._f("currency")((product.preco / 3),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", })))])])])])}),0)])]):_vm._e(),_c('section',{staticClass:"container p-0 mb-4 mt-4 pd"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Produtos em Destaque:")]),_c('carousel',{attrs:{"perPageCustom":[
        [425, 3],
        [768, 4],
        [1024, 6],
        [1440, 7],
      ],"navigationEnabled":true,"paginationEnabled":false}},_vm._l((_vm.productsList),function(product,index){return _c('slide',{key:index,style:(product.categoria.descricao == 'PNEUS'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'PNEU'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'OLEO'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'OLEOS'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'ÓLEOS'
            ? 'display: none;'
            : '')},[_c('div',{staticClass:"card",style:(product.categoria.descricao == 'PNEUS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'PNEU'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEO'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEOS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'ÓLEOS'
              ? 'display: none;'
              : ''),attrs:{"data-test":`product-${_vm.formatName(product.descricao)}`},on:{"click":function($event){$event.preventDefault();return _vm.goToProduct(
              product.categoria.descricao,
              product.descricao,
              product.id
            )}}},[(product.imagem[0] && product.imagem[0].link)?_c('div',{staticClass:"card-img",style:(`background-image: url(${_vm.ajustarImg(
              product.imagem[0].link
            )})`)}):_c('div',{staticClass:"card-img",style:(`background-image: url(${product.imageThumbnail})`)}),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(product.descricao))]),_c('h5',{staticClass:"mb-2"},[(!product.precoPromocional)?_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" ")]):_c('span',{staticClass:"price"},[_c('span',{staticClass:"normal-price"},[_c('span',{staticClass:"dashed"},[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" ")]),_c('span',{staticClass:"pct"},[_c('img',{attrs:{"src":require("@/assets/img/svg/arrow-down.svg"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.calculateDiscountPercentage( product.preco, product.precoPromocional ))+"% ")])])]),_c('span',{staticClass:"promo-price"},[_vm._v(" "+_vm._s(_vm._f("currency")(((product.precoPromocional / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" ")])]),_c('span',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" À vista no PIX ou ")])]),_c('small',[(!product.precoPromocional)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(product.preco,"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(product.precoPromocional,"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" ")]),_vm._v(" no cartão de crédito em até 3x sem juros ")])])])])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }